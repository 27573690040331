import React, { useState, useEffect } from "react";
import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React examples
import TimelineList from "components/Timeline/TimelineList";
import TimelineItem from "components/Timeline/TimelineItem";
import SuiTagInput from "components/SuiTagInput/SuiTagInput";
import { Link } from "react-router-dom";
import SuiSelect from "components/SuiSelect/SuiSelect";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";
import { useFormik } from "formik";
import axios from "axios";
import { makeHttpDownloadRequest } from "utils/httpServices";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

export default function EditAppointment() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [dentistSlots, setDentistSlots] = useState([]);
  const [dentistList, setDentistLIst] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [date, setDate] = useState("");
  const [dentist, setDentist] = useState("");
  const [selectedDentist, setSelectedDentist] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  // const [fileUrl, setFileUrl] = useState(''); // State to store the file URL
  const [status, setStatus] = useState("not_visited");

  // const tags = ["Teeth Whitening", "Filling", "Dental Bridge"];

  const [activeSlot, setActiveSlot] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleSlotClick = (index) => {
    // Set the clicked slot as active

    const slotTimes = dentistSlots[index].split("-");

    const startTime = slotTimes[0]; // "17:19:00"
    const endTime = slotTimes[1];
    const startUtc = new Date(`${date}T${startTime}`);

    const endUtc = new Date(`${date}T${endTime}`);

    const isoStartTime = startUtc.toISOString();
    const isoEndTime = endUtc.toISOString();

    setSelectedStartDate(isoStartTime);
    setSelectedEndDate(isoEndTime);

    setActiveSlot(index);
  };

  const getData = async () => {
    try {
      const resp = await makeHttpRequest("get", `viewAppointment/${id}`);
      console.log("resp", resp);
      setData(resp?.data[0]);
      console.log("is_visited", resp?.data[0]?.is_visited);
      setStatus(resp?.data[0]?.is_visited);
      setDentist(resp?.data[0].dentist_details?._id);
      const startTimeUTC = resp?.data[0]?.start_time;
      const endTimeUTC = resp?.data[0]?.end_time;

      // Convert UTC time to local time
      const startTimeLocalTime = new Date(startTimeUTC)
        ?.toLocaleString()
        .split(", ")[1];
      // new Date(new Date(startTimeUTC).getTime() + (5 * 60 + 30) * 60 * 1000)?.toLocaleTimeString('en-US', {
      //     hour12: false,
      //     hour: '2-digit',
      //     minute: '2-digit',
      //     second: '2-digit',
      //     timeZone: 'UTC',
      // })
      const endTimeLocalTime = new Date(endTimeUTC)
        ?.toLocaleString()
        .split(", ")[1];
      // new Date(new Date(endTimeUTC).getTime() + (5 * 60 + 30) * 60 * 1000)?.toLocaleTimeString('en-US', {
      //     hour12: false,
      //     hour: '2-digit',
      //     minute: '2-digit',
      //     second: '2-digit',
      //     timeZone: 'UTC',
      // })
      setStartTime(startTimeLocalTime);
      setEndTime(endTimeLocalTime);

      const utcDate = new Date(startTimeUTC);

      // Add 5 hours and 30 minutes

      const day = utcDate.getUTCDate().toString().padStart(2, "0");
      const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = utcDate.getUTCFullYear();

      const formattedDate = `${year}-${month}-${day}`;
      setDate(formattedDate);

      const Resp = await makeHttpRequest(
        "get",
        `dentistListInBranch/${resp?.data[0]?.branch_id}`
      );
      setDentistLIst(Resp?.data);
      console.log(Resp?.data);
    } catch (error) {
      //   toast.error("Network error.");
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const renderSoftButtons = () => {
    return dentistSlots.map((timeSlot, index) => (
      <SoftButton
        key={index}
        size="medium"
        color="info"
        className={activeSlot === index ? "active" : ""}
        variant="contained"
        onClick={() => handleSlotClick(index)}
        // disabled={index === 0} // Disable the first button
        style={{ margin: "5px" }}
      >
        {timeSlot}
      </SoftButton>
    ));
  };

  const getSlots = async () => {
    try {
      if (dentist) {
        const params = {
          dentist_id: dentist,
          date: date,
        };
        const resp = await makeHttpRequest(
          "get",
          "getAvailableSlotByDate",
          null,
          params
        );
        console.log(resp);
        setDentistSlots(
          resp?.data.map((val) => {
            const startTime = new Date(val?.start)
              ?.toLocaleString()
              .split(", ")[1];

            const endTime = new Date(val?.end)?.toLocaleString().split(", ")[1];

            return `${startTime}-${endTime}`;
          })
        );
      }
    } catch (error) {
      //   toast.error("Network error.");
      // console.log(error)
    }
  };
  useEffect(() => {
    getSlots();
  }, [date, dentist]);

  const handleSubmit = async () => {
    try {
      let body = {};
      if (selectedDentist !== "") {
        body = {
          start_time: selectedStartDate,
          end_time: selectedEndDate,
          is_visited: status,
          dentist_id: selectedDentist,
        };
      } else {
        body = {
          start_time: selectedStartDate,
          end_time: selectedEndDate,
          is_visited: status,
        };
      }
      const resp = await makeHttpRequest(
        "patch",
        `editAppointment/${id}`,
        body
      );
      console.log(resp);
      getData();
      toast.success("Your appointment has been updated.");
    } catch (error) {
      //   toast.error("Network error.");
    }
  };

  const handleDownload = async () => {
    if (data) {
      const url = data?.dentist_details?.profile_image;

      try {
        const response = await makeHttpDownloadRequest(url);

        const blob = new Blob([response.data]);

        // Create a virtual anchor element
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "User_receipt.png"; // Set the desired filename

        // Trigger a click on the anchor element
        document.body.appendChild(link);
        link.click();

        // Clean up the virtual anchor element
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };

  const handleVerifyInvoice = async () => {
    try {
      const obj = {
        appointment_id: data?._id,
      }
      console.log("Paylod from action", obj);
    const res = await makeHttpRequest("post", "verifyInvoice",obj,{});
    
    if(res?.data?.code == 200){

      toast.success(res?.data?.message || "Verify Invoice API to be called");
      getData()
    }else{
      toast.error(res?.data?.message || "Verification Failed");
    }

    setConfirmModal(false);

  } catch (error) {
    console.log(error)
    toast.error(error?.message || "Verification Failed");
  }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <SoftBox
                mb={3}
                component="form"
                role="form"
                className="appointment_form"
              >
                <SoftTypography variant="h6">Patient Details</SoftTypography>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          First Name <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        value={data?.user_details?.first_name}
                        placeholder="First Name"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Last Name <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        value={data?.user_details?.phone_number}
                        placeholder="Last Name"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Mobile Number <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        value={data?.user_details?.email}
                        placeholder="Number"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Email ID <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="email"
                        value={data?.user_details?.email}
                        placeholder="email"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox
                component="form"
                role="form"
                className="appointment_form"
              >
                <SoftTypography variant="h6">
                  Appointment Details
                </SoftTypography>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12} lg={4} xl={4}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Date of Appointment{" "}
                          <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="date"
                        value={date}
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={4} xs={12} lg={4} xl={4}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          From <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="time"
                        value={startTime}
                        placeholder="Start Time"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={4} xs={12} lg={4} xl={4}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          To <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="time"
                        value={endTime}
                        placeholder="End Time"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Consulting Doctor
                        </SoftTypography>
                      </SoftBox>
                      <SuiSelect
                        className="filter"
                        placeholder="Select Clinic"
                        options={dentistList.map((val) => ({
                          value: val?._id,
                          label: val?.full_name,
                        }))}
                        defaultValue={data?.dentist_details?.full_name}
                        value={selectedDentist}
                        onChange={(selectedValue) => {
                          setSelectedDentist(selectedValue);
                          setDentist(selectedValue?.value);
                        }}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xl={12}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Availability Time
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox className="slots_availability">
                      {renderSoftButtons()}
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox className="user_treatment">
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Treatment
                        </SoftTypography>
                      </SoftBox>
                      <SuiTagInput
                        tags={data?.questionires?.service?.map((val) => val)}
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox
                      style={{ position: "relative" }}
                      className="user_treatment"
                    >
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Receipt Uploaded by User
                        </SoftTypography>
                      </SoftBox>
                      {/* <SoftInput type="file" disabled />
                                            <SoftButton className="download-btn" variant="small" color="secondary" >Download</SoftButton> */}
                      <SoftInput
                        type="file"
                        disabled
                        // disabled={fileUrl !== ''}
                      />
                      <SoftButton
                        className="download-btn"
                        variant="small"
                        color="secondary"
                        onClick={handleDownload}
                        // disabled={fileUrl === ''}
                      >
                        Download
                      </SoftButton>
                    </SoftBox>
                  </Grid>

                  <Grid
                    style={{ display: "flex", flexDirection: "column",alignItems:"end",gap:"8px" }}
                    item
                    xs={6}
                    md={3}
                  >
                    <SoftBox display="flex" justifyContent="end" pt={4}>
                      <FormControl>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Status
                        </SoftTypography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          // defaultValue={status==="visited"?"Active":"Deactive"}
                          size="small"
                          value={status === "visited" ? "Active" : "Deactive"}
                        >
                          <FormControlLabel
                            value="Active"
                            control={<Radio />}
                            label="Visited"
                            disabled
                          />
                          <FormControlLabel
                            value="Deactive"
                            control={<Radio />}
                            label="Not Visited"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </SoftBox>
                    {/* {status === "visited" &&
                      data?.isInvoiceVerified == "pending" &&  (
                        <div style={{display:"flex",gap:"8px"}}>
                          <ConfirmModal
                            isOpen={confirmModal}
                            onClose={() => setConfirmModal(false)}
                            messageText={
                              "Are You Sure, you want to Verify invoice ? This Action Cannot be undone"
                            }
                            onConfirm={handleVerifyInvoice}
                          />
                          <SoftButton
                            variant="contained"
                            color="info"
                            onClick={() => {
                              setConfirmModal(true);
                            }}
                          >
                            Approve
                          </SoftButton>
                          <Button
                            variant="contained"
                            color="error"
                            style={{backgroundColor:"red",color:"white"}}
                            onClick={() => {
                              setConfirmModal(true);
                            }}
                          >
                            Reject
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{color:"white"}}
                            onClick={() => {
                              setConfirmModal(true);
                            }}
                          >
                            Review
                          </Button>
                        </div>
                      )}
                    {status === "visited" &&
                      data?.isInvoiceVerified == "pending" &&  (
                        <h5 style={{color:"gray"}}>Invoice Verified by {data?.invoice_verified_by?.name} at {new Date(
                          data?.isInvoiceVerifiedDate
                        ).toLocaleString()}</h5>
                      )} */}
                  </Grid>
                  <Grid item md={12} xs={12} lg={12}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <TimelineList title="User Preference">
                          <TimelineItem
                            color="info"
                            number="1"
                            title="What is the main purpose of your visit?"
                            // dateTime="22 DEC 7:20 PM"
                            badges={[data?.questionires?.main_purpose]}
                          />
                          <TimelineItem
                            color="success"
                            number="2"
                            title="What specific procedure are you looking to have?"
                            // dateTime="21 DEC 11 PM"
                            badges={data?.questionires?.service?.map(
                              (val) => val
                            )}
                          />
                          <TimelineItem
                            number="3"
                            title="What I'm looking for in a dentist?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={data?.questionires?.looking_in_dentist?.map(
                              (val) => val
                            )}
                          />
                          <TimelineItem
                            number="4"
                            title="When was the last time you saw a dentist?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={[data?.questionires?.last_time]}
                          />
                          <TimelineItem
                            number="5"
                            title="What time works best for your visit?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={
                              data?.questionires?.best_visit_time?.map(
                                (val) => val
                              )
                              // [data?.questionires?.best_visit_time]
                            }
                          />
                          {/* <TimelineItem
                                                        number="6"
                                                        title="What time for your visit works best for you"
                                                        dateTime="21 DEC 9:34 PM"
                                                        badges={["Morning 8am-12pm"]}

                                                    /> */}
                          <TimelineItem
                            number="7"
                            title="How soon would you like to receive care?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={[data?.questionires?.receive_care]}
                          />
                          <TimelineItem
                            number="8"
                            title="Do you have dental insurance?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={[
                              data?.questionires?.have_insurance
                                ? "Yes i have Insurance"
                                : "No I don't have insurance",
                            ]}
                          />
                          {data?.questionires?.have_insurance && (
                            <TimelineItem
                              number="9"
                              title="Who is your dental insurance provider?"
                              // dateTime="21 DEC 9:34 PM"
                              badges={[data?.questionires?.insurance]}
                              lastItem
                            />
                          )}
                        </TimelineList>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              pb={3}
            >
              <SoftButton
                style={{ marginRight: "10px" }}
                component={Link}
                to="/appointmentlist"
                variant="outlined"
                color="info"
              >
                Back
              </SoftButton>
              <SoftButton
                variant="contained"
                color="info"
                onClick={handleSubmit}
              >
                Save
              </SoftButton>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
// onChange={(e) => console.log("e.target.value", e.target.value, formatDateTime(data?.start_time).formattedDate)}
